<template>
  <div class="project-detail-screen">
    <div class="screenTitle">
      <p class="title">巡检大屏</p>
      <div v-if="type != 'add'">
        <p class="icon" @click="handleClick">
          <img class="icon" :src="'/img/project/icon-edit.png'" alt="" />
        </p>
      </div>
      <div v-else>
        <p class="icon" @click="cancleClick">
          <img class="icon" :src="'/img/project/icon-close.png'" alt="" />
        </p>
      </div>
    </div>
    <!-- 编辑大屏信息 -->
    <div v-if="type == 'add'" class="edit-box">
      <div class="from-box">
        <!-- 主标题 -->
        <div class="name">
          <p class="name-title title">
            <span><a class="required">*</a>大屏标题</span>
          </p>
          <div class="name-input">
            <div class="name-content content">
              <el-input class="uav-input" v-model="formDetail.name" 
                :placeholder="$t('inputPlease')" :maxlength="20"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="finish">
        <el-button class="finish-btn" :loading="saveLoading" type="primary" @click="save">{{$t('save')}}</el-button>
      </div>
    </div>
    <!-- 查看大屏信息 -->
    <div v-else class="show-box">
      <div class="show-box-top">
        <!-- 主标题 -->
        <div class="name">
          <p class="name-title title">
            <span>大屏标题</span>
          </p>
          <div class="name-input">
            <div class="name-content title">
              <span>{{ projectInfo.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="finish">
        <el-button class="finish-btn" type="primary" 
          @click="viewScreen">{{$t('screen.viewLargeScreen')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getOilInspectionScreenDetail, saveOilInspectionScreen} from "@/api/oil-gas/index";

export default {
  name: "OG-edit-screen",
  data() {
    return {
      projectInfo: {}, // 项目信息
      type: 'view', // 显示或者编辑状态 view 、 add
      formDetail: {}, // 编辑表单
      saveLoading: false, // 保存等待
    };
  },
  created() {
    this.getOilInspectionScreenDetail();
  },
  methods: {
    // 获取大屏基础信息
    getOilInspectionScreenDetail() {
      return new Promise((resolve, reject) => {
        getOilInspectionScreenDetail({type: 5}).then((res) => {
          if (res.data.code === 200) {
            this.projectInfo = res.data.data.records[0] || {};
            resolve();
          }
        }).catch(() => {
          reject();
        });
      });
    },
    // 编辑大屏信息
    handleClick() {
      this.formDetail = JSON.parse(JSON.stringify(this.projectInfo));
      this.type = "add";
    },
    // 关闭编辑
    cancleClick() {
      this.type = 'view';
    },
    // 保存大屏信息
    save() {
      let data = {
        id: this.formDetail.id,
        name: this.formDetail.name
      }
      this.saveLoading = true;
      saveOilInspectionScreen(data).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.getOilInspectionScreenDetail().then(()=>{
            this.type = "view";
            this.$emit('save');
          });
        }
      }).catch((err) => {
        this.$message.error(err.msg);
      }).finally(() => {
        this.saveLoading = false;
      });
    },
    // 查看大屏
    viewScreen() {
      this.$router.push({
        path: '/screen-oil',
        query: {},
      });
    }
  }
};
</script>

<style lang="scss">
.project-detail-screen {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  width: 290px;
  padding: 10px;
  background-color: #1e222a;
  .screenTitle {
    display: flex;
    align-items: center;
    height: 46px;
  }

  .edit-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 47px);
    overflow: hidden;
    .from-box {
      flex: 1;
      overflow: auto;
    }
  }

  .show-box {
    display: flex;
    flex-direction: column;
    height: calc(100% - 47px);
    .show-box-top {
      flex: 1;
      overflow: auto;
      .file-name-line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .file-name {
          color: #aaa;
          max-width: calc(100% - 60px);
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .el-radio {
          padding-top: 3px;
        }
      }
    }
    .finish {
      text-align: center;
      margin-left: 10px;
      height: 36px;
      &-btn {
        width: 217px;
        height: 36px;
        line-height: 36px;
        background: #265edd;
        border-radius: 3px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
        color: #ffffff;
        padding: 0;
        border: none;
      }
    }
  }

  .title {
    flex: 1;
    size: 16px;
    color: #FFFFFF;
  }

  .name-content {
    color: #aaa;
    .screen_input {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      padding: 0 5px;
      color: #ffffff;
      cursor: pointer;
      border: 1px solid #535353;
      background: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
  }

  p.title {
    padding: 15px 5px 10px 0px;
  }

  .icon-container {
    flex-shrink: 0;
  }

  .icon {
    cursor: pointer;
    width: 15px;
    /* 根据需要调整图标的宽度 */
    height: 15px;
    /* 根据需要调整图标的高度 */
  }

  .required {
    color: red;
    margin-right: 5px;
  }

  .finish {
    margin-top: 22px;
    text-align: center;
    margin-left: 10px;
    height: 36px;
    &-btn {
      width: 217px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
    }
  }

  .el-input {
    width: 100%;
    .el-input__inner {
      background-color: #030303;
      border: 1px solid #535353;
      color: #FFFFFF;
      text-align: left;
    }
  }
}
</style>
