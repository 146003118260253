var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-detail-screen" }, [
    _c("div", { staticClass: "screenTitle" }, [
      _c("p", { staticClass: "title" }, [_vm._v("巡检大屏")]),
      _vm.type != "add"
        ? _c("div", [
            _c("p", { staticClass: "icon", on: { click: _vm.handleClick } }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: "/img/project/icon-edit.png", alt: "" },
              }),
            ]),
          ])
        : _c("div", [
            _c("p", { staticClass: "icon", on: { click: _vm.cancleClick } }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: "/img/project/icon-close.png", alt: "" },
              }),
            ]),
          ]),
    ]),
    _vm.type == "add"
      ? _c("div", { staticClass: "edit-box" }, [
          _c("div", { staticClass: "from-box" }, [
            _c("div", { staticClass: "name" }, [
              _vm._m(0),
              _c("div", { staticClass: "name-input" }, [
                _c(
                  "div",
                  { staticClass: "name-content content" },
                  [
                    _c("el-input", {
                      staticClass: "uav-input",
                      attrs: {
                        placeholder: _vm.$t("inputPlease"),
                        maxlength: 20,
                      },
                      model: {
                        value: _vm.formDetail.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formDetail, "name", $$v)
                        },
                        expression: "formDetail.name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "finish" },
            [
              _c(
                "el-button",
                {
                  staticClass: "finish-btn",
                  attrs: { loading: _vm.saveLoading, type: "primary" },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              ),
            ],
            1
          ),
        ])
      : _c("div", { staticClass: "show-box" }, [
          _c("div", { staticClass: "show-box-top" }, [
            _c("div", { staticClass: "name" }, [
              _vm._m(1),
              _c("div", { staticClass: "name-input" }, [
                _c("div", { staticClass: "name-content title" }, [
                  _c("span", [_vm._v(_vm._s(_vm.projectInfo.name))]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "finish" },
            [
              _c(
                "el-button",
                {
                  staticClass: "finish-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.viewScreen },
                },
                [_vm._v(_vm._s(_vm.$t("screen.viewLargeScreen")))]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [
        _c("a", { staticClass: "required" }, [_vm._v("*")]),
        _vm._v("大屏标题"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "name-title title" }, [
      _c("span", [_vm._v("大屏标题")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }