<template>
  <div class="OG-screen-main">
    <screen-manage/>
    <cusium-map></cusium-map>
  </div>
</template>

<script>
  import CusiumMap from "@/components/cesium/index";
  import screenManage from './components/screenManage.vue'
  import { getProjectList } from "@/api/oil-gas/index";
  import { mapMutations } from "vuex";
  
  export default {
    name: "OG-screen",
    components: { screenManage, CusiumMap },
    data() {
      return {};
    },
    mounted(){
      this.getList({})
    },
    methods: {
      ...mapMutations([
        'SET_MAP3D_OG_LIST'
      ]),
      getList(parmas) {
        parmas.current = 1;
        parmas.size = 10000;
        getProjectList(parmas).then((res) => {
          if (res.data.code === 200) {
            this.projectData = res.data.data.records;
            this.SET_MAP3D_OG_LIST(res.data.data.records)
          }
        })
      },
    }
  };
</script>

<style lang="scss">
.OG-screen-main{
  height: 100%;
  display: flex;
}
</style>
  